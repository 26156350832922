<template>
  <div class="container-fluid conference-apply steps-container-wrapper">
    <ifac-loader v-if="busy"></ifac-loader>
    <div v-else>
      <ifac-conference-application-wizard
        v-if="isEditable(conference)"
        :is-admin="true"
        :conference="conference"
        :countries="countries"
        :affiliates="affiliates"
        :budget-file-id="budgetFileId"
        :people-list-id="peopleListId"
        :has-errors="hasErrors(conference)"
        :conference-types="conferenceTypes"
        :last-section="lastSection(conference, $route)"
        :uncompleted-sections="uncompletedSections(conference)"
        @update="preparePayload"
        @save-exit="updateAndExit"
        @save-continue="updateConference"
        @change-fees="changeFile('budgetFileId', $event)"
        @change-people-list="changeFile('peopleListId', $event)"
      ></ifac-conference-application-wizard>
      <ifac-conference-summary
        v-else
        read-only
        :countries="countries"
        :conference="conference"
        :affiliates="affiliates"
        :conference-types="conferenceTypes"
        :technical-committees="technicalCommittees"
        :uncompleted-sections="uncompletedSections(conference)"
      ></ifac-conference-summary>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import S3Uploader from '@/services/S3Uploader';
import Affiliates from '@/services/Api/Affiliates';
import Conferences from '@/services/Api/Conferences';
import {
  IfacLoader,
  IfacConferenceSummary,
  IfacConferenceApplicationWizard,
} from '@ifac/ui';

export default {
  name: 'ConferenceDetails',
  components: {
    IfacLoader,
    IfacConferenceSummary,
    IfacConferenceApplicationWizard,
  },
  created() {
    this.fetchConference();
    this.fetchAffiliates();
  },
  data() {
    return {
      busy: false,
      section: null,
      payload: null,
      conference: null,
      affiliates: null,
      budgetFileId: null,
      peopleListId: null,
    };
  },
  computed: {
    ...mapGetters({
      countries: 'countries/data',
      conferenceTypes: 'conferenceTypes/data',
      technicalCommittees: 'coordinatingCommittees/technicalCommitteeList',
    }),
    ...mapGetters('conferences', {
      conferences: 'conferences',
      uncompletedSections: 'uncompletedSections',
      lastSection: 'lastSection',
      hasErrors: 'hasErrors',
      metas: 'metas',
      isEditable: 'isEditable',
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapMutations({
      setUploadStatus: 'uiHelpers/setConferenceFileUpload',
      setRequestError: 'formHelpers/setError',
    }),
    async fetchAffiliates() {
      try {
        const {
          data: {
            data: { items },
          },
        } = await Affiliates.get(
          1,
          9999,
          null,
          null,
          'desc',
          null,
          ['contactInfo'],
        );
        this.affiliates = items;
      } catch (e) {
        console.log(e);
        this.affiliates = [];
      }
    },
    async fetchConference() {
      this.busy = true;
      const { data } = await Conferences.get(this.id);
      this.conference = data.data;
      this.busy = false;
    },
    preparePayload(data) {
      this.section = data.section;
      this.payload = data.payload;
    },
    updatePayload() {
      return {
        ...this.payload,
        ...{ id: this.id },
      };
    },
    filePayload($e) {
      return {
        id: this.id,
        fileName: $e.file.name,
      };
    },
    changeFile(field, $e) {
      this.setUploadStatus(true);
      Conferences.uploadFile(this.filePayload($e))
        .then((response) => {
          const { id: uploadId, url } = response.data.data;
          S3Uploader.put(url, $e.file)
            .then(() => {
              this.$data[field] = uploadId;
            }).catch(() => {
              this.$snack.success({
                text: 'Something went wrong while uploading the file, please try again.',
              });
            });
        }).catch(() => {
          this.$snack.success({
            text: 'Something went wrong while uploading the file, please try again.',
          });
        }).finally(() => {
          this.setUploadStatus(false);
        });
    },
    async updateConference() {
      try {
        const { data } = await Conferences.update(this.updatePayload());
        this.conference = data.data;
        this.setRequestError(null);
      } catch (error) {
        if (error.response?.status === 422) {
          this.$snack.success({
            text: error.response.data.error.message,
          });
          this.setRequestError(error.response.data.error);
        } else {
          this.$snack.success({
            text: 'There was an error updating the section, please try again.',
          });
        }
      }
    },
    async updateAndExit() {
      await this.updateConference();
      this.$router.push({ name: 'ConferencesOverview' });
    },
  },
};
</script>
